"use strict";
function scrollSpy() {
  var c = 5,
    e = document.querySelector("html"),
    l =
      (e &&
        (e = window.getComputedStyle(e).scrollPaddingTop) &&
        "string" == typeof e &&
        "auto" !== e &&
        e.endsWith("px") &&
        (c += parseInt(e.split("px")[0])),
      "nav-scroll-active"),
    i = null,
    d = [];
  function t() {
    i = null;
    var e = document.querySelectorAll("[data-traverse-target]");
    Array.prototype.forEach.call(e, function (e) {
      d.push({ id: e.id, top: e.offsetTop });
    });
  }
  var n = debounce(function () {
      t(), o();
    }, 500),
    o = debounce(function () {
      var e,
        t,
        n,
        o,
        r = (function (e) {
          for (var t = e + c, n = 0; n < d.length; n++) {
            var o = d[n + 1];
            if (t >= d[n].top && (!o || t < o.top)) return n;
          }
          return -1;
        })(document.documentElement.scrollTop || document.body.scrollTop);
      r !== i &&
        ((r = d[(i = r)]),
        (e = document.querySelector("." + l)),
        (t = s(
          (r = r
            ? document.querySelector('#nav a[href="#' + r.id + '"]')
            : null)
        )),
        (n = (o = s(e)) !== t),
        o && n && u(o, !1),
        t && n && u(t, !0),
        r &&
          (r.classList.add(l),
          r.scrollIntoViewIfNeeded
            ? r.scrollIntoViewIfNeeded()
            : !r.scrollIntoView ||
              (0 <= (o = (o = r).getBoundingClientRect()).top &&
                0 <= o.left &&
                o.bottom <=
                  (window.innerHeight ||
                    document.documentElement.clientHeight) &&
                o.right <=
                  (window.innerWidth ||
                    document.documentElement.clientWidth)) ||
              r.scrollIntoView({ block: "center", inline: "start" })),
        e) &&
        e.classList.remove(l);
    }, 100);
  function u(e, t) {
    for (var n = t ? "add" : "remove"; e; )
      e.classList[n]("nav-scroll-expand"), (e = s(e.parentNode));
  }
  function s(e) {
    return e && e.closest ? e.closest(".nav-group-section") : null;
  }
  setTimeout(function () {
    t(),
      o(),
      window.addEventListener("scroll", o),
      window.addEventListener("resize", n);
  }, 300);
}
function toggleMenu() {
  var t = "drawer-open",
    e = document.querySelector("#spectaql .sidebar-open-button"),
    n = document.querySelector("#spectaql #sidebar .close-button"),
    o = document.querySelector("#spectaql .drawer-overlay");
  function r() {
    var e = document.querySelector("#spectaql #page");
    e.classList.contains(t) ? e.classList.remove(t) : e.classList.add(t);
  }
  if(e){
    e.addEventListener("click", r)
  }
  if(n){
    n.addEventListener("click", r)
  }
  if(o){
    o.addEventListener("click", r);
  }
}
function debounce(e, t) {
  var n = null;
  return function () {
    clearTimeout(n),
      (n = setTimeout(function () {
        e.apply(null);
      }, t));
  };
}
window.addEventListener("DOMContentLoaded", (e) => {
  toggleMenu(), scrollSpy();
});
